*,
*::after,
*::before {
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	height: 100%;
}

html {
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-font-smoothing: antialiased;
	-ms-overflow-style: scrollbar;
	scroll-behavior: smooth;
}
