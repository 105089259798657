// Here you can add other styles

body {
	font-family: TTNorms, sans-serif;
}

.form-control.is-invalid {
	// background-image: none; // remove cross image
}

.cover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-ThinItalic.ttf") format("truetype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-ExtraLight.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-ExtraLightItalic.ttf") format("truetype");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-ExtraBold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-ExtraBoldItalic.ttf") format("truetype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-Heavy.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "TTNorms";
	src: local("TTNorms"),
		url("/assets/fonts/TTNorms-HeavyItalic.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
}

html {
	overflow-x: hidden;
	overflow-y: auto;
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	-ms-overflow-style: scrollbar;

	&.disable-scroll {
		overflow-y: hidden;
	}

	@media (max-width: 1200px) {
		font-size: 60%;
	}

	@media (max-width: 767px) {
		font-size: 58%;

		&.disable-scroll-mobile {
			overflow-y: hidden;
		}
	}
}

body {
	font-family: "TTNorms", Helvetica, Arial, sans-serif;
	font-size: 1.8rem;
}

label {
	font-weight: normal;
	cursor: pointer;
}

a,
a:hover,
a:active,
a:focus {
	color: inherit;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	font-weight: bold;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	margin: 0;
	-webkit-appearance: none;
}

.App {
	&__modal {
		border-radius: 20px;
		min-height: 50px;
		min-width: 50px;
		// max-width: 430px;
		// max-height: 90vh;
		background-color: #fff;
		padding: 30px 25px 30px 30px;
		position: relative;

		@media (max-width: 849px) {
			max-width: 80vw;
		}

		&:focus,
		&:active {
			outline: none;
			border: 0;
		}

		&__overlay {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #00000099;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 15;
			&,
			&:focus,
			&:active {
				outline: none;
			}
		}
	}

	&__fullmodal {
		width: 100%;
		height: 100%;
		background-color: #fff;
		padding: 30px 10px;
		position: relative;
		overflow-y: auto;

		&:focus,
		&:active {
			outline: none;
			border: 0;
		}

		&__overlay {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #00000099;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 15;
			&,
			&:focus,
			&:active {
				outline: none;
			}
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: 0;
	-webkit-text-fill-color: #fff;
	-webkit-box-shadow: 0 0 0px 1000px transparent inset;
	transition: background-color 5000s ease-in-out 0s;
	font-family: TTNorms, sans-serif;
	font-weight: 500;
	color: #777a99;
}
